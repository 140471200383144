<template>
  <div class="">
    <!-- Card Fondos -->
    <div
      class="b-card b-card--shadow-small b-card--hover pointer"
      @click="open()"
    >
      <div class="card-fondo">
        <div class="card-fondo__content">
          <div class="card-fondo__ico">
            <div class="ico-box ico-box--45">
              <img :src="$fundIcons[fund.fundTypeId]" alt="Tipo de fondo" />
            </div>
          </div>
          <div class="card-fondo__title" v-text="fund.title"></div>
        </div>
        <div class="card-fondo__actions">
          <span class="card-fondo__link">{{ $t("guest.funds.contribute") }}</span>
        </div>
      </div>
    </div>

    <!-- Modal box -->
    <FluxFondos
      :key="fund.id"
      :show="showModal"
      :fund="fund"
      :list="list"
      @close="closeStepsModal()"
      @finish="finish()"
    ></FluxFondos>

    <transition name="bbFade" @after-enter="showSuccessModalContent = true">
      <div class="modal" v-show="showSuccessModal" @click="showContent = false">
        <transition name="bbUp" @after-leave="closeShowSuccessModal()">
          <div
            class="modal__card modal__card--mini"
            v-show="showSuccessModalContent"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showSuccessModalContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="card-success">
                  <div class="card-success__ico">
                    <img
                      src="../../../assets/img/ico/ico-color-success.svg"
                      alt="¡Gracias!"
                    />
                  </div>
                  <h3 class="card-success__header">{{ $t("guest.funds.contributionDone") }}</h3>
                  <div class="card-success__content">
                    {{ $t("guest.funds.notificationMsg", { name: list.ownerName }) }}
                  </div>
                  <div class="card-success__actions">
                    <a
                      href="#"
                      @click="showSuccessModalContent = false"
                      class="button button--primary button--w60"
                      >{{ $t("guest.funds.great") }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <modal-dialog
      :show="showWarningModal"
      @close="showWarningModal = false"
      target-class="modal__card--mini"
    >
      <div class="card-success">
        <div class="card-success__ico mar-t-0">
          <img src="../../../assets/img/ico/ico-round-fondos-success.svg" alt="" />
        </div>
        <h3 class="card-success__header">{{ $t("guest.funds.notPossible") }}</h3>

        <div class="card-success__content mar-b-0">
          {{ $t("guest.funds.piggybankNotEnabled", { name: list.ownerName }) }}
        </div>
      </div>
    </modal-dialog>
  </div>
</template>
<script>
import ModalDialog from "../../../components/ui/ModalDialog";
import FluxFondos from "../components/FluxFondos.vue";
import { mapGetters } from "vuex";

export default {
  name: "ListFondosCard",
  components: {
    FluxFondos,
    ModalDialog,
  },
  computed: {
    ...mapGetters({
      myListHasPiggyBankEnabled: "myListHasPiggyBankEnabled",
    }),
  },
  props: {
    fund: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      showWarningModal: false,
      showSuccessModal: false,
      showSuccessModalContent: false,
    };
  },
  methods: {
    closeShowSuccessModal() {
      this.showSuccessModal = false;
      document.body.classList.remove("modal-open");
    },
    open() {
      if (!this.myListHasPiggyBankEnabled) {
        return this.openWarningModal();
      }

      return this.openStepsModal();
    },
    openStepsModal() {
      this.showModal = true;
      document.body.classList.add("modal-open");
    },
    closeStepsModal() {
      this.showModal = false;
      document.body.classList.remove("modal-open");
    },
    finish() {
      this.closeStepsModal();
      document.body.classList.add("modal-open");
      this.showSuccessModal = true;
    },
    openWarningModal() {
      this.showWarningModal = true;
    },
    closeWarningModal() {
      this.showWarningModal = false;
    },
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.showSuccessModalContent = false;
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });

    this.$nextTick(() => {
      this.$store.dispatch("setMyList", this.list);
    });
  },
};
</script>

<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s opacity ease-out;
}
</style>
